import { Component } from '@angular/core';
import { AuthService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {
  
  constructor(
    private authService: AuthService) { }

    logout() {
      this.authService.logout();
    }
}