import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, AlertsService } from 'src/app/shared/services';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.less']
})
export class SignInComponent {

  constructor(
    private router: Router,
    private auth: AuthService,
    private alerts: AlertsService,
  ) { }

  ngOnInit() {
    if (this.auth.isUserAuthenticated()) {
      this.router.navigateByUrl('/certifications');
    }
  }

  login() {
    try {
      this.auth.loginRedirect();
    } catch (error) {
      this.alerts.showError('An error occurred while logging in. Please try again later.');
    }
  }
}