<div class="background"
  [style.background-image]="'url(/assets/04b41180daaf07f196daf0073965ffa1.png)'"
  [style.background-size]="'cover'"
  [style.background-position]="'center center'"
  [style.background-repeat]="'no-repeat'" [style.height]="'100vh'">
  <div class="center-content">
    <header>
      <h2 class="header">Welcome</h2>
      <span class="subheader">
        Factor
        <img
          src="/assets/1c2d523ac99e22cd60d568d9ee45c859.png"
          alt="logo factor-k"
          onerror="this.onerror=null;" />
        Software
      </span>
      <p class="header centered-text">EWC | Certifications</p>
      <nav>
        <button (click)="login()" class="custom-btn" title="Sign In">Sign In</button>
      </nav>
    </header>
  </div>
</div>

<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
<script
  src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
<script
  src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>