import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from '../components';
import { LoaderComponent } from '../components/loader/loader.component';

import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        NgbNavModule, 
        RouterModule,
        CommonModule, 
    ],
    declarations: [
        HeaderComponent,
        LoaderComponent,
    ],
    exports: [
        HeaderComponent,
        LoaderComponent,
    ]
})
export class SharedModule { }